/* src/components/Navbar/Navbar.css */

/* Basic styles */

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
}



.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-toggler {
  display: none;
  font-size: 3rem;
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  margin-left: auto; /* Pushes the toggle to the right */
  padding: 0; /* Ensure no padding inflates the size */
  width: auto; /* Auto width to restrict unnecessary growth */
  height: auto; /* Auto height to restrict unnecessary growth */
}

.navbar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex; /* Ensure flex display by default */
  flex-direction: column; /* Column direction for smaller screens */
  align-items: center;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.navbar-item {
  width: 100%;
  text-align: center;
}

.navbar-item a,
.navbar-item .auth-button {
  display: block;
  width: 100%;
  padding: 1rem;
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background: none;
}

.navbar-logo {
  height: 40px; 
  margin-right: 10px; 
}

.auth-button {
  background-color: #007bff;
  color: white;
  /* border-radius: 4px; */
  /* margin: 0.5rem 0; */
  transition: background-color 0.3s;
}

.auth-button:hover {
  background-color: #0056b3;
}

.cart-icon {
  position: relative;
  margin-left: auto; /* Pushes the cart icon to the right */
  font-size: 1.5rem;
  color: #333;
}

.cart-icon a {
  color: inherit; /* Inherit color to keep the icon consistent */
  text-decoration: none;
  position: relative;
}

.cart-icon i {
  font-size: 1.8rem;
  color: #007bff;
}

.cart-badge {
  position: absolute;
  top: -.5rem;
  right: 2rem;
  background-color: red;
  color: white;
  font-size: 0.8rem;
  padding: 0.2rem 0.4rem;
  border-radius: 50%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-icon:hover {
  color: #0056b3;
}


/* Responsive styles */
@media (max-width: 768px) {

  .cart-icon {
    margin-right: 10px;
  }

  .navbar-toggler {
    display: block;
    margin-left: auto;
  }

  .navbar-menu {
    flex-direction: column;
    max-height: 100vh; /* Ensure space for all items */
    overflow-y: auto; /* Enable scrolling if content exceeds height */
  }

  .navbar-item a,
  .navbar-item, .auth-button {
    padding: 1rem 1rem; /* Ensure same padding for all items */
    width: 100%; /* Make sure buttons span the full width like links */
    text-align: center; /* Center the text */
    display: block; /* Block display ensures consistency */
    margin: 0; /* Remove any extra margin */
  }

  .navbar-item {
    padding: 0; /* Reset padding */
    margin: 0; /* Ensure uniform margin */
    width: 100%; /* Ensure all items take up full width */
  }





}

@media (min-width: 769px) {
  .navbar-menu {
    position: static;
    flex-direction: row; /* Row direction for larger screens */
    background-color: transparent;
    box-shadow: none;
    height: auto !important;
    opacity: 1 !important;
    display: flex !important;
    justify-content: flex-end; /* Align items to the end of the navbar */
  }

  .navbar-item {
    width: auto; /* Ensure auto width for items */
    margin-left: 2rem;
    text-align: left;
  }

  .navbar-item:first-child {
    margin-left: 0; /* Remove margin from the first item */
  }

  .navbar-item a,
  .navbar-item .auth-button {
    padding: 0;
    color: #333;
    font-size: 1rem;
  }
}