.consultation-booking-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .consultation-booking-container h3 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .consultation-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  /* Calendar container to stay fixed size */
  .react-datepicker-wrapper,
  .react-datepicker {
    margin-right: 20px; /* Add some space between the calendar and form */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for aesthetic */
  }
  
  .booking-form-container {
    display: flex;
    flex-direction: column;
  }
  
  @media (min-width: 768px) {
    .consultation-content {
      flex-direction: row;
    }
  
    .booking-form-container {
      flex-direction: column;
      flex: 1;
      margin-left: 20px;
    }
  }
  
  .timeslots {
    margin-top: 20px;
  }
  
  .scrollable-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    font-size: 1rem;
    height: auto;
    max-height: 150px; /* Adjust the max height to control scrollability */
    overflow-y: scroll;
  }
  
  .booking-form {
    margin-top: 20px;
  }
  
  .booking-form label {
    display: block;
    margin: 10px 0 5px;
  }
  
  .booking-form input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .submit-btn {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .submit-btn:hover {
    background-color: #218838;
  }
  
  .react-datepicker__day--highlighted-available {
    background-color: #28a745;
    color: white;
  }
  
  .react-datepicker__day--highlighted-unavailable {
    background-color: #dc3545;
    color: white;
  }
  
  @media (max-width: 600px) {
    .consultation-booking-container {
      padding: 10px;
    }
  
    .submit-btn {
      font-size: 1.2rem;
    }
  
    .consultation-content {
      flex-direction: column;
    }
  
    .booking-form-container {
      margin-left: 0;
    }
  }