/* PricingSection.css */
.pricing-section {
  margin: auto;
  padding: 1rem;
  height: 80vh;
}

.product {
  padding: 10px;
  margin-bottom: 2rem;
  margin-top: 4rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  background: #fff;
  border-radius: 10px;
}

.product img {
  max-width: 100%;
  height: 40vh;
  border-radius: 10px;
  margin-bottom: 15px;
  margin: auto;
}

.slick-slide > div {
  margin: 0 30px; /* Adjust the margin as needed */
}
/* Add custom styling as needed */
