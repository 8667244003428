iframe {
width: 100%;
height: 200px; /* Adjust height as needed */
overflow: hidden;
}

.cart-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  min-height: 90vh;
}

.cart-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.cart-item {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cart-item-image img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.cart-item-details {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.remove-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.remove-btn:hover {
  background-color: #d32f2f;
}

.cart-summary {
  margin-top: 20px;
  text-align: right;
}

.clear-cart-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.clear-cart-btn:hover {
  background-color: #d32f2f;
}

@media (max-width: 600px) {

.cart-badge {

  margin-right: -2.5rem;
}

}